






















import { Component, Mixins, Prop } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import RulesMixin from "@/mixins/RulesMixin";
import BaseForm from "@/components/form/BaseForm";
import AnalyticsService from "@/services/AnalyticsService";
import {
  AnalyticsQuery,
  DeleteProjectPayloadEnumType,
} from "@/entity/Analytics";
import fileDownload from "js-file-download";

@Component({
  components: {
    FormCard,
  },
})
export default class DownloadAnalyticsCard extends Mixins(
  RulesMixin,
  BaseForm
) {
  @Prop({ required: true, type: Boolean }) showDeviation!: boolean;
  @Prop({ required: true, type: Array })
  removedProjects!: DeleteProjectPayloadEnumType[];
  @Prop({ default: () => ({}), type: Object }) query!: AnalyticsQuery;

  fileName = "";

  onFileNameKeypress(event: KeyboardEvent): void {
    if (event.key === "Enter" && this.fileName.length > 0) {
      event.preventDefault();
      this.submitForm();
    }
  }

  async submitForm(): Promise<void> {
    this.$emit("export", this.fileName);
    this.$emit("exported");
  }
}
