









































export default {
  props: {
    showDeviation: {
      type: Boolean,
      required: true,
    },
    years: {
      type: Array,
      required: true,
    },
    showAverage: {
      type: Boolean,
      default: true,
    },
    isPeerGroup: {
      type: Boolean,
      default: false,
    },
  },
};
