<template functional>
  <tr class="empty-row">
    <td class="no-border"><div /></td>
    <td><div /></td>
    <td v-for="col in props.yearCols" :key="col"><div /></td>
    <td class="no-border"><div /></td>
    <td><div /></td>
  </tr>
</template>

<script>
export default {
  props: {
    yearCols: {
      type: Number,
      required: true,
    },
  },
};
</script>
