






































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import { ProjectIndustryScore } from "@/entity/project-valuation/ProjectValuationScore";

@Component({
  components: {
    ActionButtonWithTooltip,
  },
})
export default class AnalyticsNavigation extends Vue {
  @PropSync("activeTab", { required: true, type: Number })
  activeTabLocal!: number;
  @Prop({ required: true, type: Number }) incomeStatementLength!: number;
  @Prop({ required: true, type: Number }) balanceSheetLength!: number;
  @Prop({ required: true, type: Number }) derivedIndicatorsLength!: number;
  @Prop({ required: false, type: Object })
  industryScoreData?: ProjectIndustryScore;
  @Prop({ required: true, type: Array }) tabItems!: any[];

  get disableToggleAllPanels(): boolean {
    if (this.activeTabLocal === 0) {
      return this.incomeStatementLength === 0;
    } else if (this.activeTabLocal === 1) {
      return this.balanceSheetLength === 0;
    } else if (this.activeTabLocal === 2) {
      return this.derivedIndicatorsLength === 0;
    }
    return false;
  }

  isTabDisabled(tabKey: string): boolean {
    if (tabKey === "incomeStatement") {
      return this.incomeStatementLength === 0;
    } else if (tabKey === "balanceSheet") {
      return this.balanceSheetLength === 0;
    } else if (tabKey === "derivedIndicators") {
      return this.derivedIndicatorsLength === 0;
    } else if (tabKey === "industryScoring") {
      return !this.industryScoreData;
    }
    return true;
  }
}
