





































import { Vue, Component, Prop } from "vue-property-decorator";
import AnalyticsTableCell from "@/components/analytics/analytics-table/AnalyticsTableCell.vue";
import {
  AnalyticsIndicatorProject,
  AnalyticsIndicatorProjectData,
} from "@/entity/Analytics";
import { PROJECT_FINANCE_PERCENTAGE_INDICATOR } from "@/constants";
import { percentage } from "@/utils/validations";
import { getRowAverage } from "../utils";

@Component({
  components: {
    AnalyticsTableCell,
  },
})
export default class AnalyticsTableFooterRow extends Vue {
  @Prop({ required: true, type: Boolean }) showDeviation!: boolean;
  @Prop({ required: true, type: Boolean }) percentage!: boolean;
  @Prop({ required: true, type: Array }) years!: number[];
  @Prop({ required: true, type: Array })
  averageData!: AnalyticsIndicatorProjectData[];
  @Prop({ required: true, type: String }) keyFigureName!: string;
  @Prop({ required: true, type: Array }) data!: AnalyticsIndicatorProject[];

  getColumnAverage(year: number): number | undefined {
    return this.averageData.find((item) => item.year === year)?.value;
  }

  getColumnRatioAverage(year: number): number | undefined {
    if (PROJECT_FINANCE_PERCENTAGE_INDICATOR.includes(this.keyFigureName))
      return undefined;
    const result = this.averageData.find((item) => item.year === year)?.ratio;
    if (!result) return undefined;
    return Number(result.toFixed(1));
  }

  get averageOfVerticalAverages() {
    const arrayOfRowAverages = this.data.map((item) => ({
      value: getRowAverage(item.data),
    }));
    return getRowAverage(arrayOfRowAverages);
  }
}
