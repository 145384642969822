







import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Rating extends Vue {
  @Prop({ type: Number, default: null }) value!: number | null;

  get absoluteValue(): number {
    if (this.value == null) return 0;

    return Math.trunc(this.value);
  }

  get showHalfStar(): boolean {
    if (this.value == null) {
      return false;
    }

    const decimal = this.value - Math.floor(this.value);
    return decimal >= 0.5;
  }
}
