import { PROJECT_FINANCE_PERCENTAGE_INDICATOR } from "@/constants";
import Analytics, {
  AnalysisComparisonKeyFigures,
  TabItemsType,
} from "@/entity/Analytics";
import {
  calculateColumnAverages,
  calculateColumnRatioAverage,
  getAnalyticsYears,
} from ".";

// This function add the percentage (ratio) of the prices to the according comparision key prices
export const processAnalyticsResponse = (
  data: Analytics,
  comparisionKeyFigures: AnalysisComparisonKeyFigures
) => {
  const result = { ...data };
  Object.keys(comparisionKeyFigures).forEach((comparisonKeyFigure) => {
    const comparisonKeyFigureCopy = comparisonKeyFigure as TabItemsType;
    const comparisonData = result[comparisonKeyFigureCopy].find(
      (item) => item.variable === comparisionKeyFigures[comparisonKeyFigureCopy]
    );
    result[comparisonKeyFigureCopy] = result[comparisonKeyFigureCopy].map(
      (item) => {
        const updatedItem = { ...item };
        const years = getAnalyticsYears(item.data);
        updatedItem.averageData = [];
        for (const year of years) {
          updatedItem.averageData.push({
            year,
            value: calculateColumnAverages(updatedItem.data, year),
          });
        }
        if (
          !PROJECT_FINANCE_PERCENTAGE_INDICATOR.includes(
            updatedItem.variable
          ) &&
          comparisonData &&
          comparisionKeyFigures[comparisonKeyFigureCopy]
        ) {
          updatedItem.data = updatedItem.data.map((item2) => {
            return {
              ...item2,
              data: item2.data.map((item3) => {
                let ratio;
                if (
                  item.variable !==
                  comparisionKeyFigures[comparisonKeyFigureCopy]
                ) {
                  const currentPrice = item3.value;
                  const comparisonPrice =
                    comparisonData?.data
                      .find(
                        (comparisonDataItem) =>
                          comparisonDataItem.projectName === item2.projectName
                      )
                      ?.data.find((_) => _.year === item3.year)?.value || 0;
                  ratio = Number((currentPrice * 100) / comparisonPrice);
                }
                return {
                  ...item3,
                  ratio,
                };
              }),
            };
          });
        }
        return updatedItem;
      }
    );
  });
  Object.keys(result).forEach((ind) => {
    const indicator = ind as TabItemsType;
    result[indicator].forEach((analyticsIndicator) => {
      if (
        PROJECT_FINANCE_PERCENTAGE_INDICATOR.includes(
          analyticsIndicator.variable
        )
      ) {
        analyticsIndicator.averageData = analyticsIndicator.averageData.map(
          (item) => ({ ...item, ratio: item.value * 100 })
        );
      } else {
        const years = getAnalyticsYears(analyticsIndicator.data);
        for (const year of years) {
          const ratio = calculateColumnRatioAverage(
            analyticsIndicator.data,
            year
          );
          analyticsIndicator.averageData = analyticsIndicator.averageData.map(
            (item) => {
              if (item.year === year) return { ...item, ratio };
              return item;
            }
          );
        }
      }
    });
  });
  return result;
};
