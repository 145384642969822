






















import { Component, Mixins, Prop } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import RulesMixin from "@/mixins/RulesMixin";
import BaseForm from "@/components/form/BaseForm";
import { ProjectIndustryScoreWithProjects } from "@/entity/project-valuation/ProjectValuationScore";
import fileDownload from "js-file-download";
import * as XLSX from "xlsx";

@Component({
  components: {
    FormCard,
  },
})
export default class DownloadIndustryScoreCard extends Mixins(
  RulesMixin,
  BaseForm
) {
  @Prop({ required: true, type: Object })
  industryScoreData!: ProjectIndustryScoreWithProjects | null;

  fileName = "";

  onFileNameKeypress(event: KeyboardEvent): void {
    if (event.key === "Enter" && this.fileName.length > 0) {
      event.preventDefault();
      this.submitForm();
    }
  }

  async submitForm(): Promise<void> {
    const scores = [
      "Anzahl Kaufinteressenten",
      "Anzahl Kaufangebote",
      "Projektlaufzeit",
    ];
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const wsPrimaryCameras = XLSX.utils.json_to_sheet(
      scores.map((score, index) => {
        if (index === 0) {
          return {
            Score: score,
            Branche:
              this.industryScoreData?.overallResult.potentialBuyers.score,
            Overall:
              this.industryScoreData?.overallResult.potentialBuyers
                .overallScore,
            Rating:
              this.industryScoreData?.overallResult.potentialBuyers.rating,
          };
        } else if (index === 1) {
          return {
            Score: score,
            Branche: this.industryScoreData?.overallResult.purchaseOffers.score,
            Overall:
              this.industryScoreData?.overallResult.purchaseOffers.overallScore,
            Rating: this.industryScoreData?.overallResult.purchaseOffers.rating,
          };
        } else {
          return {
            Score: score,
            Branche:
              this.industryScoreData?.overallResult.projectDuration.score,
            Overall:
              this.industryScoreData?.overallResult.projectDuration
                .overallScore,
            Rating:
              this.industryScoreData?.overallResult.projectDuration.rating,
          };
        }
      }) || []
    );

    const projects = XLSX.utils.json_to_sheet(
      this.industryScoreData?.projects.map((item) => ({
        Project: item.projectName,
        [scores[0]]: item.potentialBuyers.score,
        [scores[1]]: item.purchaseOffers.score.toFixed(1),
        [scores[2]]: item.projectDuration.score.toFixed(1),
      })) || []
    );

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, wsPrimaryCameras, "Overall Result");
    XLSX.utils.book_append_sheet(wb, projects, "Projects");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    fileDownload(data, `${this.fileName}.xlsx`);
    this.$emit("exported");
  }
}
