









































import { Vue, Component, Prop } from "vue-property-decorator";
import AnalyticsTableCell from "@/components/analytics/analytics-table/AnalyticsTableCell.vue";
import { AnalyticsIndicatorProject } from "@/entity/Analytics";
import { getRowAverage } from "@/components/analytics/utils/index";

interface DataWithDeviation {
  [key: string]: Deviation;
}

interface Deviation {
  value: number;
  deviation: string;
  ratio?: number;
}

@Component({
  components: {
    AnalyticsTableCell,
  },
})
export default class AnalyticsTableMainRow extends Vue {
  @Prop({ required: true, type: Boolean }) showDeviation!: boolean;
  @Prop({ required: true, type: Boolean }) percentage!: boolean;
  @Prop({ required: true, type: Number }) index!: number;
  @Prop({ required: true, type: Array }) years!: number[];
  @Prop({ required: true, type: Object }) row!: AnalyticsIndicatorProject;

  deleteProject(projectContactId: number) {
    this.$emit("delete-project", projectContactId);
  }

  get rowAverage(): number {
    return getRowAverage(this.row.data);
  }

  get dataWithDeviation(): DataWithDeviation {
    const deviations: DataWithDeviation = {};

    this.years.forEach((year) => {
      const columnData = this.row.data.find((row) => row.year === year);

      if (columnData !== undefined) {
        this.row.data.forEach((item) => {
          let deviationValue = "N/A";

          if (this.showDeviation) {
            const deviation = this.row.deviation.find(
              (deviation) =>
                deviation.year.split("/")[1] === item.year.toString()
            );

            if (deviation) {
              deviationValue = deviation.value.toString();
            }
          }

          deviations[item.year] = {
            value: item.value,
            deviation: deviationValue,
            ratio: item.ratio,
          };
        });
      } else {
        deviations[year] = {
          value: 0,
          deviation: "deviationValue",
          ratio: 0,
        };
      }
    });

    return deviations;
  }
}
