





















import { Vue, Component, Prop } from "vue-property-decorator";
import AnalyticsTableHeader from "@/components/analytics/analytics-table/AnalyticsTableHeader.vue";
import AnalyticsTableFirstEmptyRow from "@/components/analytics/analytics-table/AnalyticsTableFirstEmptyRow.vue";
import AnalyticsSummaryTableMainRow from "@/components/analytics/analytics-table/AnalyticsSummaryTableMainRow.vue";

@Component({
  components: {
    AnalyticsTableFirstEmptyRow,
    AnalyticsTableHeader,
    AnalyticsSummaryTableMainRow,
  },
})
export default class AnalyticsSummaryTable extends Vue {
  @Prop({ required: true, type: Array }) summaryData!: Record<any, any>[];

  deleteProject(projectContactId: number) {
    this.$emit("delete-project", projectContactId);
  }

  get years(): number[] {
    return Object.keys(this.summaryData[0])
      .map((item) => Number(item))
      .filter((item) => !isNaN(item));
  }
}
