









































































import { Component, Vue, Prop } from "vue-property-decorator";
import Rating from "@/components/Rating.vue";
import ScoreTableTotalRow from "@/components/project-valuation-score/ProjectValuationScoreTableTotalRow.vue";
import { ProjectIndustryScoreWithProjects } from "@/entity/project-valuation/ProjectValuationScore";

@Component({
  components: {
    Rating,
    ScoreTableTotalRow,
  },
})
export default class IndustryScoreAnalysis extends Vue {
  @Prop({ type: Object }) industryScore!: ProjectIndustryScoreWithProjects;

  get scores() {
    return [
      {
        name: this.$t("valuation.score.industry.potentialBuyers"),
        score: this.industryScore.overallResult.potentialBuyers.score,
        overallScore:
          this.industryScore.overallResult.potentialBuyers.overallScore,
        rating: this.industryScore.overallResult.potentialBuyers.rating,
      },
      {
        name: this.$t("valuation.score.industry.purchaseOffers"),
        score: this.industryScore.overallResult.purchaseOffers.score.toFixed(1),
        overallScore:
          this.industryScore.overallResult.purchaseOffers.overallScore.toFixed(
            1
          ),
        rating: this.industryScore.overallResult.purchaseOffers.rating,
      },
      {
        name: this.$t("valuation.score.industry.projectDuration"),
        score:
          this.industryScore.overallResult.projectDuration.score.toFixed(1),
        overallScore:
          this.industryScore.overallResult.projectDuration.overallScore.toFixed(
            1
          ),
        rating: this.industryScore.overallResult.projectDuration.rating,
      },
    ];
  }

  averageDataForProjects(
    column: "potentialBuyers" | "purchaseOffers" | "projectDuration"
  ) {
    return (
      this.industryScore.projects.reduce(
        (sum, item) => sum + item[column].score,
        0
      ) / this.industryScore.projects.length
    ).toFixed(1);
  }
}
