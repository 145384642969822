




























































































































































































































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import GlobalService, { FinancialItemType } from "@/services/GlobalService";
import { ReferenceItem } from "@/services/types";
import Industry, { IndustryType } from "@/entity/Industry";
import IndustryService from "@/services/IndustryService";
import { AnalyticsQuery } from "@/entity/Analytics";
import AnalyticsService from "@/services/AnalyticsService";
import Contact from "@/entity/Contact";
import { PROJECT_FINANCE_PERCENTAGE_INDICATOR } from "@/constants";

@Component
export default class AnalyticsForm extends Vue {
  @Prop({ required: true, type: Number })
  width!: number;
  @PropSync("showDeviation", { required: true, type: Boolean })
  localShowDeviation!: boolean;
  @PropSync("formData", { required: true, type: Object })
  localFormData!: AnalyticsQuery;

  isFetching = false;

  balanceIndicatorOptions: ReferenceItem[] = [];
  incomeStatementOptions: ReferenceItem[] = [];
  derivedIndicatorOptions: ReferenceItem[] = [];
  industryOptions: Industry[] = [];
  subIndustryOptions: ReferenceItem[] = [];
  contactOptions: Contact[] = [];

  subIndustryIds = [];

  incomeStatementComparisionKeyFigure = "";
  balanceComparisionKeyFigure = "";
  derivedComparisionKeyFigure = "";

  activeTab = 0;
  selectedIndustryForScoring = "";

  get years(): number[] {
    const startYear = 2010;
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = startYear; i <= currentYear + 1; i++) {
      years.push(i);
    }
    return years;
  }

  get selectedIncomeStatementIndicators() {
    return this.incomeStatementOptions
      .filter(
        (item) =>
          this.localFormData.incomeStatementIndicators.includes(item.id) &&
          !PROJECT_FINANCE_PERCENTAGE_INDICATOR.includes(item.name)
      )
      .map((item) => item.name);
  }

  get selectedBalanceIndicators() {
    return this.balanceIndicatorOptions
      .filter(
        (item) =>
          this.localFormData.balanceIndicators.includes(item.id) &&
          !PROJECT_FINANCE_PERCENTAGE_INDICATOR.includes(item.name)
      )
      .map((item) => item.name);
  }

  get selectedDerivedIndicators() {
    return this.derivedIndicatorOptions
      .filter(
        (item) =>
          this.localFormData.derivedIndicators.includes(item.id) &&
          !PROJECT_FINANCE_PERCENTAGE_INDICATOR.includes(item.name)
      )
      .map((item) => item.name);
  }

  onBranchSelect(data: number[]) {
    this.reset("branchField");

    // Find all projects that are belong to the selected branch
    // and add those projects to selection
    const newIndustry = data[data.length - 1]; // New is always the last one
    const contactIds = this.contactOptions
      .filter((contact) => {
        const index =
          contact.contactBranches?.findIndex(
            (branch) => branch.id === newIndustry
          ) ?? -1;
        return index > -1;
      })
      .map((project) => project.id as number);

    contactIds.forEach((contactId) => {
      if (this.localFormData.contactId.indexOf(contactId) === -1) {
        this.localFormData.contactId.push(contactId);
      }
    });
  }

  onSubBranchSelect(data: number[]) {
    this.reset("subBranchField");

    // Find all projects that are belong to the selected subBranch
    // and add those projects to selection
    const newIndustry = data[data.length - 1]; // New is always the last one
    const contactIds = this.contactOptions
      .filter((contact) => {
        const index =
          contact.subBranches?.findIndex(
            (branch) => branch.id === newIndustry
          ) ?? -1;
        return index > -1;
      })
      .map((project) => project.id as number);

    contactIds.forEach((contactId) => {
      if (this.localFormData.contactId.indexOf(contactId) === -1) {
        this.localFormData.contactId.push(contactId);
      }
    });
  }

  async fetchData(): Promise<void> {
    try {
      this.isFetching = true;
      [
        this.balanceIndicatorOptions,
        this.incomeStatementOptions,
        this.derivedIndicatorOptions,
        this.industryOptions,
        this.contactOptions,
        this.subIndustryOptions,
      ] = await Promise.all([
        GlobalService.getFinancialItems(FinancialItemType.BALANCE),
        GlobalService.getFinancialItems(FinancialItemType.INCOME_STATEMENT),
        GlobalService.getFinancialItems(FinancialItemType.DERIVED_INDICATORS),
        IndustryService.findAllByType(IndustryType.INDUSTRY),
        AnalyticsService.contacts(),
        GlobalService.getSubIndustries(),
      ]);
    } finally {
      this.isFetching = false;
    }
  }

  submitForm() {
    this.$emit("search", this.localFormData, {
      incomeStatement: this.incomeStatementComparisionKeyFigure,
      balanceSheet: this.balanceComparisionKeyFigure,
      derivedIndicators: this.derivedComparisionKeyFigure,
    });
  }

  submitIndustryScoring() {
    const industry = this.industryOptions.find(
      (item) => item.name === this.selectedIndustryForScoring
    );
    if (!industry)
      return console.error("Error while trying to fetch Industry Scoring");
    this.$emit("fetchIndustryScoring", industry);
  }

  resetForm() {
    this.localFormData = {
      balanceIndicators: [],
      incomeStatementIndicators: [],
      derivedIndicators: [],
      branchIds: [],
      contactId: [],
      yearFrom: null,
      yearTo: null,
    };
    this.$emit("reset");
  }

  reset(name: string) {
    (this.$refs[name] as any).lazySearch = "";
  }

  created() {
    this.fetchData();
  }

  get tabItems() {
    return [
      {
        name: this.$tc("financialIndicators"),
        key: "financialIndicators",
      },
      {
        name: this.$tc("valuation.score.industry"),
        key: "industryScoring",
      },
    ];
  }
}
