import { formatPrice } from "@/utils/string";

export interface AnalyticsDisplayValueOptions {
  percentage?: boolean;
}

export const displayValue = (
  value: number | string,
  options: AnalyticsDisplayValueOptions = {}
): string => {
  let localValue: number | string =
    typeof value === "string" ? parseFloat(value) : value;

  if (options.percentage === true) {
    localValue = Math.round(localValue * 100);

    if (isNaN(localValue) || !isFinite(localValue)) {
      return "N/A";
    }

    return `${localValue}%`;
  }

  localValue = Math.round(localValue);

  return formatPrice(localValue);
};
