








































































import { Component, Ref, Mixins } from "vue-property-decorator";
import AnalyticsForm from "@/components/analytics/AnalyticsForm.vue";
import AnalyticsContent from "@/components/analytics/AnalyticsContent.vue";
import ResizableSidebarLayout from "@/layouts/ResizableSidebarLayout.vue";
import Analytics, {
  AnalyticsQuery,
  DeleteProjectPayload,
  DeleteProjectPayloadEnumType,
  IndicatorType,
  AnalysisComparisonKeyFigures,
  TabItemsType,
} from "@/entity/Analytics";
import AnalyticsService from "@/services/AnalyticsService";
import AnalyticsNavigation from "@/components/analytics/AnalyticsNavigation.vue";
import DialogMixin from "@/mixins/DialogMixin";
import DownloadAnalyticsCard from "@/components/analytics/DownloadAnalyticsCard.vue";
import DownloadIndustryScoreCard from "@/components/analytics/DownloadIndustryScoreCard.vue";
import { cloneDeep } from "lodash";
import Industry from "@/entity/Industry";
import IndustryService from "@/services/IndustryService";
import { ProjectIndustryScoreWithProjects } from "@/entity/project-valuation/ProjectValuationScore";
import IndustryScoreAnalysisTable from "@/components/analytics/IndustryScoreAnalysisTable.vue";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import { processAnalyticsResponse } from "@/components/analytics/utils/processAnalyticsResponse";
import * as XLSX from "xlsx";
import { displayValue } from "@/utils/analytics";
import { PROJECT_FINANCE_PERCENTAGE_INDICATOR } from "@/constants";

@Component({
  components: {
    ResizableSidebarLayout,
    AnalyticsForm,
    AnalyticsContent,
    AnalyticsNavigation,
    DownloadAnalyticsCard,
    DownloadIndustryScoreCard,
    IndustryScoreAnalysisTable,
    SectionTitle,
  },
})
export default class AnalyticsView extends Mixins(DialogMixin) {
  @Ref("analyticsContent") analyticsContent!: AnalyticsContent;

  activeTab = 0;
  isFetching = false;
  showDeviation = false;
  query: AnalyticsQuery | null = null;
  removedProjects: DeleteProjectPayloadEnumType[] = [];
  data: Analytics = {
    balanceSheet: [],
    incomeStatement: [],
    derivedIndicators: [],
  };
  industryScoreData: ProjectIndustryScoreWithProjects | null = null;
  selectedIndustry: Industry | null = null;
  comparisonKeyFigures: AnalysisComparisonKeyFigures = {
    balanceSheet: "",
    derivedIndicators: "",
    incomeStatement: "",
  };
  formData: AnalyticsQuery = {
    balanceIndicators: [],
    incomeStatementIndicators: [],
    derivedIndicators: [],
    branchIds: [],
    contactId: [],
    yearFrom: null,
    yearTo: new Date().getFullYear() - 1,
  };

  deleteProject(payload: DeleteProjectPayload) {
    this.formData = {
      ...this.formData,
      contactId: this.formData.contactId.filter(
        (item) => item !== payload.projectContactId
      ),
    };
    this.fetchAnalytics(this.formData, this.comparisonKeyFigures);
  }

  async fetchAnalytics(
    query: AnalyticsQuery,
    comparisionKeyFigures: AnalysisComparisonKeyFigures
  ): Promise<void> {
    try {
      this.isFetching = true;
      this.comparisonKeyFigures = comparisionKeyFigures;
      this.query = cloneDeep(query);
      const response = await AnalyticsService.search(query);
      const responseWithRatios = processAnalyticsResponse(
        response,
        comparisionKeyFigures
      );
      this.data.balanceSheet = responseWithRatios.balanceSheet;
      this.data.incomeStatement = responseWithRatios.incomeStatement;
      this.data.derivedIndicators = responseWithRatios.derivedIndicators;
      this.removedProjects = [];
    } finally {
      this.isFetching = false;
    }
  }

  reset(): void {
    this.data.balanceSheet = [];
    this.data.incomeStatement = [];
    this.data.derivedIndicators = [];
    this.removedProjects = [];
  }

  getIndicatorTypeEnum(type: string): IndicatorType {
    if (type === "balanceSheet") {
      return IndicatorType.BALANCE_SHEET;
    } else if (type === "incomeStatement") {
      return IndicatorType.INCOME_STATEMENT;
    } else if (type === "derivedIndicators") {
      return IndicatorType.DERIVED_INDICATORS;
    }
    throw Error("Invalid type");
  }

  toggleAllPanels() {
    this.analyticsContent.toggleAllPanels();
  }

  get tabItems() {
    return [
      {
        name: this.$tc("incomeStatement"),
        key: "incomeStatement",
      },
      {
        name: this.$tc("balance"),
        key: "balanceSheet",
      },
      {
        name: this.$tc("identificationNumber", 2),
        key: "derivedIndicators",
      },
      {
        name: this.$tc("valuation.score.industry"),
        key: "industryScoring",
      },
    ];
  }

  async fetchIndustryScoring(industry: Industry) {
    if (!industry.id) return console.error("Industry ID not available");
    try {
      const res = await IndustryService.getIndustryScoring(industry.id);
      this.industryScoreData = res;
      this.selectedIndustry = industry;
      this.activeTab = this.tabItems.findIndex(
        (item) => item.key === "industryScoring"
      );
    } catch (error) {
      console.log(error);
    }
  }

  exportAnalytics(filename: string) {
    const firstColumnHeader = "name";
    const projectNameHeader = "Project Name";

    const finalData: Record<string, any> = {};
    Object.keys(this.data).forEach((indicator) => {
      this.data[indicator as TabItemsType].forEach((analyticsIndicator) => {
        analyticsIndicator.data.forEach((analyticsIndicatorProject) => {
          if (!finalData[indicator]) finalData[indicator] = {};
          if (!finalData[indicator][analyticsIndicator.variable])
            finalData[indicator][analyticsIndicator.variable] = [];

          const object1: Record<string, any> = {
            [projectNameHeader]: analyticsIndicatorProject.projectName,
          };
          analyticsIndicatorProject.data.forEach(
            (analyticsIndicatorProjectData) => {
              const value = analyticsIndicatorProjectData.value;
              object1[analyticsIndicatorProjectData.year] = !value
                ? ""
                : PROJECT_FINANCE_PERCENTAGE_INDICATOR.includes(
                    analyticsIndicator.variable
                  )
                ? Number(value.toFixed(2))
                : Math.round(value);
            }
          );
          finalData[indicator][analyticsIndicator.variable].push(object1);
        });
      });
    });

    const wb = XLSX.utils.book_new();

    Object.keys(finalData).forEach((mainIndicator) => {
      const data: any[] = [];
      const summary = this.summaryTableItems(mainIndicator as TabItemsType);
      Object.keys(finalData[mainIndicator]).forEach((analyticsIndicator) => {
        data.push({});
        data.push({ [firstColumnHeader]: analyticsIndicator });
        data.push(finalData[mainIndicator][analyticsIndicator]);
        data.push({});
      });
      data.push({});
      data.push({ [firstColumnHeader]: "Summary" });
      data.push(summary);
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(data.flat(), {
          header: [firstColumnHeader, projectNameHeader],
        }),
        this.$tc(mainIndicator)
      );
    });

    XLSX.writeFile(wb, `${filename}.xlsx`, { compression: true });
  }

  get selectedIndustryName() {
    return this.selectedIndustry?.name || "";
  }

  summaryTableItems(tab: TabItemsType) {
    setTimeout(() => {
      const tableElements = document.querySelectorAll<HTMLElement>("table");
      for (const tableElement of tableElements) {
        if (tableElement) {
          const firstRow = tableElement.children[2]?.children[0] as
            | HTMLElement
            | undefined;
          if (firstRow) firstRow.style.backgroundColor = "rgba(0,0,0,0.1)";
        }
      }
    }, 10);
    const items = this.data[tab].map((item) => {
      const object: Record<any, any> = {
        name: item.variable,
      };
      item.averageData.forEach((averageData) => {
        object[averageData.year] =
          averageData.ratio !== undefined
            ? `${averageData.ratio?.toFixed(1)}%`
            : displayValue(averageData.value);
      });
      return object;
    });

    // Set the Comparision key figure to first place in array
    items.splice(
      0,
      0,
      items.splice(
        items.findIndex((item) => item.name === this.comparisonKeyFigures[tab]),
        1
      )[0]
    );

    if (!items[0]) return null;

    return items;
  }
}
