



































import { Component, Prop, Vue } from "vue-property-decorator";
import Tabs from "@/components/common/Tabs.vue";
import AnalyticsTable from "@/components/analytics/analytics-table/AnalyticsTable.vue";
import AnalyticsSummaryTable from "@/components/analytics/analytics-table/AnalyticsSummaryTable.vue";
import AnalyticsExpansions from "@/components/analytics/AnalyticsExpansions.vue";
import Analytics, {
  TabItemsType,
  AnalysisComparisonKeyFigures,
} from "@/entity/Analytics";
import { displayValue } from "@/utils/analytics";

@Component({
  components: {
    Tabs,
    AnalyticsTable,
    AnalyticsExpansions,
    AnalyticsSummaryTable,
  },
})
export default class AnalyticsContent extends Vue {
  @Prop({ required: true, type: Object }) data!: Analytics;
  @Prop({ required: true, type: Number }) activeTab!: number;
  @Prop({ required: true, type: Array }) tabItems!: any[];
  @Prop({ default: false, type: Boolean })
  showDeviation!: boolean;
  @Prop({ required: true, type: Object })
  comparisonKeyFigures!: AnalysisComparisonKeyFigures;
  @Prop({ required: true, type: Function }) summaryTableItems!: (
    tab: TabItemsType
  ) => void;

  panel: { [key: string]: number[] } = {
    incomeStatement: [],
    balanceSheet: [],
    derivedIndicators: [],
  };

  deleteProject(
    projectContactId: number,
    indicatorName: string,
    indicatorType: string
  ) {
    this.$emit("delete-project", {
      projectContactId,
      indicatorName,
      indicatorType,
    });
  }

  generateIndexesFromLength(length: number): number[] {
    const indexes = [];
    for (let i = 0; i < length; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  get deviation() {
    if (this.activeTab === 3) {
      return false;
    }

    return this.showDeviation;
  }

  toggleAllPanels() {
    if (this.activeTab === 0) {
      if (this.panel.incomeStatement.length === 0) {
        this.panel.incomeStatement = this.generateIndexesFromLength(
          this.data.incomeStatement.length
        );
      } else {
        this.panel.incomeStatement = [];
      }
    } else if (this.activeTab === 1) {
      if (this.panel.balanceSheet.length === 0) {
        this.panel.balanceSheet = this.generateIndexesFromLength(
          this.data.balanceSheet.length
        );
      } else {
        this.panel.balanceSheet = [];
      }
    } else if (this.activeTab === 2) {
      if (this.panel.derivedIndicators.length === 0) {
        this.panel.derivedIndicators = this.generateIndexesFromLength(
          this.data.derivedIndicators.length
        );
      } else {
        this.panel.derivedIndicators = [];
      }
    }
  }

  get sortedData() {
    let sortedData = { ...this.data };
    Object.keys(this.data).forEach((i) => {
      const tabItem = i as TabItemsType;
      if (this.comparisonKeyFigures[tabItem]) {
        const fromIndex = sortedData[tabItem].findIndex(
          (item) => item?.variable === this.comparisonKeyFigures[tabItem]
        );
        const element = sortedData[tabItem].splice(fromIndex, 1)[0];
        sortedData[tabItem].splice(0, 0, element);
      }
    });
    return sortedData;
  }

  get tableDataForSummary() {
    const activeTab = this.tabItems[this.activeTab].key as TabItemsType;
    return this.summaryTableItems(activeTab);
  }
}
