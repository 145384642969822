import Axios, { AxiosResponse } from "axios";
import Analytics, {
  AnalyticsQuery,
  ExportAnalyticsQuery,
} from "@/entity/Analytics";
import Contact from "@/entity/Contact";

export default class AnalyticsService {
  public static mapQuery(query: AnalyticsQuery) {
    const result: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(query)) {
      if (value === null || (Array.isArray(value) && value.length === 0)) {
        continue;
      }
      result[key] = value.toString();
    }
    return result;
  }

  public static async search(query: AnalyticsQuery): Promise<Analytics> {
    const response = await Axios.get("/api/v2/analytics", {
      params: this.mapQuery(query),
    });
    return response.data;
  }

  public static async contacts(): Promise<Contact[]> {
    const response = await Axios.get("/api/v2/analytics/contacts");
    return response.data;
  }

  public static async export(
    query: ExportAnalyticsQuery
  ): Promise<AxiosResponse<Blob>> {
    return await Axios.post(
      "/api/v2/analytics/export",
      {
        ...query,
      },
      {
        responseType: "blob",
      }
    );
  }
}
