















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ScoreTableTotalRow extends Vue {
  @Prop({ type: Number }) rating!: number | null;

  get ratingReadable(): string {
    if (this.rating === null) {
      return this.$tc("na");
    }

    return this.rating.toFixed(1);
  }
}
