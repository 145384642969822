import {
  AnalyticsIndicator,
  AnalyticsIndicatorProject,
  AnalyticsIndicatorProjectData,
} from "@/entity/Analytics";

export const getYearsListFromAnalyticsIndicator = (
  data: AnalyticsIndicator[]
) => {
  let years: number[] = [];
  data.forEach((item) => (years = years.concat(getAnalyticsYears(item.data))));
  return [...new Set(years)];
};

export const getAnalyticsYears = (
  data: AnalyticsIndicatorProject[]
): number[] => {
  const years: Set<number> = new Set();

  data?.forEach((row) => {
    if (Array.isArray(row.data)) {
      row.data.forEach((cell: any) => {
        years.add(cell.year);
      });
    }
  });

  return [...years].sort();
};

export const calculateColumnRatioAverage = (
  data: AnalyticsIndicatorProject[],
  year: number
): number | undefined => {
  let totalValue = 0;
  let validRows = 0;

  data.forEach((row) => {
    const yearData = row.data.find((projectData) => {
      return projectData.year === year;
    });

    if (yearData?.ratio && !isNaN(yearData.ratio) && yearData.ratio !== 0) {
      totalValue = totalValue + yearData.ratio;
      validRows++;
    }
  });

  const result = totalValue / validRows;

  if (isNaN(result)) {
    return undefined;
  }

  return result;
};

export const calculateColumnAverages = (
  data: AnalyticsIndicatorProject[],
  year: number
): number => {
  let totalValue = 0;
  let validRows = 0;

  data.forEach((row) => {
    const yearData = row.data.find((projectData) => {
      return projectData.year === year;
    });

    if (yearData && !isNaN(yearData.value) && yearData.value !== 0) {
      totalValue = totalValue + yearData.value;
      validRows++;
    }
  });

  const result = totalValue / validRows;

  if (isNaN(result)) {
    return 0;
  }

  return result;
};

export const getRowAverage = (data: { value: number }[]) => {
  let totalValue = 0;
  let validRows = 0;

  data.forEach((row) => {
    if (!isNaN(row.value) && row.value !== 0) {
      totalValue = totalValue + row.value;
      validRows++;
    }
  });

  const result = totalValue / validRows;

  if (isNaN(result)) {
    return 0;
  }

  return result;
};
