import { render, staticRenderFns } from "./AnalyticsExpansions.vue?vue&type=template&id=89bf18e6&scoped=true&functional=true&"
import script from "./AnalyticsExpansions.vue?vue&type=script&lang=ts&"
export * from "./AnalyticsExpansions.vue?vue&type=script&lang=ts&"
import style0 from "./AnalyticsExpansions.vue?vue&type=style&index=0&id=89bf18e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "89bf18e6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader})
