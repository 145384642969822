


















import { Vue, Component, Prop } from "vue-property-decorator";
import AnalyticsSummaryTableCell from "@/components/analytics/analytics-table/AnalyticsSummaryTableCell.vue";

@Component({
  components: {
    AnalyticsSummaryTableCell,
  },
})
export default class AnalyticsSummaryTableMainRow extends Vue {
  @Prop({ required: true, type: Number }) index!: number;
  @Prop({ required: true, type: Object }) summaryData!: Record<any, any>;

  get dataWithDeviation() {
    const deviations: any = {};

    Object.keys(this.summaryData).forEach((header) => {
      if (!isNaN(Number(header))) {
        deviations[header] = this.summaryData[header];
      }
    });

    return deviations;
  }

  get className() {
    return this.index == 0 ? "colored" : "";
  }
}
