
import { CreateElement, VNode, RenderContext } from "vue";

export default {
  functional: true,
  props: {
    empty: {
      default: false,
      type: Boolean,
    },
    text: {
      required: true,
      type: String,
    },
    small: {
      default: false,
      type: Boolean,
    },
    italic: {
      default: false,
      type: Boolean,
    },
    colored: {
      default: false,
      type: Boolean,
    },
  },
  render(createElement: CreateElement, context: RenderContext<any>): VNode {
    const classes = [];

    if (context.props.italic) {
      classes.push("font-italic");
    }

    if (context.props.small) {
      classes.push("small");
    }

    if (context.props.colored) {
      classes.push("bozi");
    }

    const cellContent = createElement(
      "div",
      {
        attrs: {
          class: classes.join(" "),
        },
      },
      context.props.empty ? "" : context.props.text
    );

    return createElement("td", {}, [cellContent]);
  },
};
