




export default {
  functional: true,
  props: {
    title: {
      required: true,
      type: String,
    },
  },
};
