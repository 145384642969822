













export default {
  props: {
    data: {
      required: true,
      type: Object,
    },
    bold: {
      default: false,
      type: Boolean,
    },
  },
};
