







































import { Vue, Component, Prop } from "vue-property-decorator";
import { AnalyticsIndicator } from "@/entity/Analytics";
import AnalyticsTableHeader from "@/components/analytics/analytics-table/AnalyticsTableHeader.vue";
import AnalyticsTableCell from "@/components/analytics/analytics-table/AnalyticsTableCell.vue";
import AnalyticsTableFirstEmptyRow from "@/components/analytics/analytics-table/AnalyticsTableFirstEmptyRow.vue";
import AnalyticsTableEmptyRow from "@/components/analytics/analytics-table/AnalyticsTableEmptyRow.vue";
import AnalyticsTableMainRow from "@/components/analytics/analytics-table/AnalyticsTableMainRow.vue";
import AnalyticsTableFooterRow from "@/components/analytics/analytics-table/AnalyticsTableFooterRow.vue";
import { PROJECT_FINANCE_PERCENTAGE_INDICATOR } from "@/constants";
import { getAnalyticsYears } from "../utils";

@Component({
  components: {
    AnalyticsTableCell,
    AnalyticsTableFirstEmptyRow,
    AnalyticsTableEmptyRow,
    AnalyticsTableHeader,
    AnalyticsTableMainRow,
    AnalyticsTableFooterRow,
  },
})
export default class AnalyticsTable extends Vue {
  @Prop({ required: true, type: Object }) data!: AnalyticsIndicator;
  @Prop({ default: false, type: Boolean }) showDeviation!: boolean;

  deleteProject(projectContactId: number) {
    this.$emit("delete-project", projectContactId);
  }

  get isPercentageIndicator(): boolean {
    return PROJECT_FINANCE_PERCENTAGE_INDICATOR.includes(this.data.variable);
  }

  get columnMultiply() {
    return this.showDeviation ? 2 : 1;
  }

  get years(): number[] {
    return getAnalyticsYears(this.data.data);
  }
}
