
import { CreateElement, VNode, RenderContext } from "vue";
import { displayValue } from "@/utils/analytics";

export default {
  functional: true,
  props: {
    empty: {
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
      type: [String, Number],
    },
    ratio: {
      required: false,
      type: Number,
    },
    isPercentage: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
    italic: {
      default: false,
      type: Boolean,
    },
  },
  render(createElement: CreateElement, context: RenderContext<any>): VNode {
    const classes = [];

    if (context.props.italic) {
      classes.push("font-italic");
    }

    if (context.props.small) {
      classes.push("small");
    }

    const cellContent = createElement(
      "div",
      {
        attrs: {
          class: classes.join(" "),
        },
      },
      context.props.empty
        ? ""
        : [
            `${displayValue(context.props.value, {
              percentage: context.props.isPercentage,
            })}`,
            createElement(
              "span",
              {
                attrs: {
                  style: "color: gray; font-size: 0.7rem",
                },
              },
              context.props.ratio
                ? ` (${Number(context.props.ratio.toFixed(1))}%)`
                : ""
            ),
          ]
    );

    return createElement("td", {}, [cellContent]);
  },
};
