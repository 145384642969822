











export default {
  name: "AnalyticsTableEmptyRow",
  props: {
    yearsLength: {
      type: Number,
      required: true,
    },
    columnMultiply: {
      type: Number,
      required: true,
    },
  },
};
